




















































































































import { Component, Watch, PropSync, Ref, Vue } from "vue-property-decorator";
import { VesselEvent } from "@/types/vesselEvent";
import { TrendEventType } from "@/types/trendEventType";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import VesselEventsModule from "@/store/clients/VesselEvents.module";
import VesselsModule from "@/store/clients/Vessels.module";

const VesselEvents = getModule(VesselEventsModule, store);
const Vessels = getModule(VesselsModule, store);

@Component
export default class EditVesselEvent extends Vue {
  @Ref("typeSelect") typeSelect!: any;
  @PropSync("isOpen", { type: Boolean, default: false }) dialog!: boolean;
  resolve!: any;
  reject!: any;
  isNewEvent = true;
  isFormValid = true;
  submitting = false;
  dateMenu = false;
  selectedType: any = {};

  id?: number;
  name = "";
  source = "Office";
  timeStamp = "";
  type = "";
  typeId: number | null = null;
  vesselId = Vessels.currentVessel?.id;

  nameValidation = {
    required: (value: string) => !!value || "Event name required",
  };

  dateValidation = {
    required: (value: string) => !!value || "Event date required",
  };

  typeSelectValidation = {
    required: (value: TrendEventType) => (!!value && !!value.trendEventTypeId) || "Type required",
  };

  get title(): string {
    if (this.isNewEvent) {
      return "Create event";
    } else {
      return "Edit event";
    }
  }

  get trendEventTypes(): TrendEventType[] | null {
    return VesselEvents.trendEventTypes;
  }

  //reset the inputs when switching between type in case of new event
  @Watch("type")
  typeChanged(val: string): void {
    if (!this.isNewEvent) return;
    if (val && val === "InfoEvent") {
      this.typeId = null;
      this.name = "";
    } else if (val && val === "TrendEvent") {
      this.$nextTick(() => {
        this.typeSelect.reset();
      });
    }
  }

  selectTrendEventType(trendEventType: TrendEventType): void {
    this.typeId = trendEventType.trendEventTypeId;
    this.name = trendEventType.trendEventTypeName;
  }

  open(vesselEvent?: VesselEvent): Promise<unknown> {
    this.dialog = true;
    if (vesselEvent) {
      this.setFieldData(vesselEvent);
    }

    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  async handleSubmit(): Promise<void> {
    const payload: VesselEvent = {
      name: this.name,
      source: this.source,
      timestamp: this.timeStamp,
      type: this.type,
      vesselId: this.vesselId,
    };

    if (this.id) {
      payload.id = this.id;
    }

    if (this.typeId) {
      payload.typeId = this.typeId;
    }

    this.submitting = true;
    if (this.isNewEvent) {
      await VesselEvents.createEvent(payload);
    } else {
      await VesselEvents.editEvent(payload);
    }

    this.submitting = false;
    this.confirm();
  }

  confirm(): void {
    this.dialog = false;
    this.resetFieldData();
    this.resolve(true);
  }

  cancel(): void {
    this.resetFieldData();
    this.resolve(null);
    this.dialog = false;
  }

  resetFieldData(): void {
    this.isNewEvent = true;
    this.isFormValid = true;
    this.dateMenu = false;
    this.selectedType = {};
    this.id = 0;
    this.name = "";
    this.source = "Office";
    this.timeStamp = "";
    this.type = "";
    this.typeId = null;
    this.vesselId = Vessels.currentVessel?.id;
    this.selectedType = {};
  }

  setFieldData(vesselEvent: VesselEvent): void {
    this.isNewEvent = false;
    this.id = vesselEvent.id;
    this.name = vesselEvent.name;
    this.source = vesselEvent.source;
    //incoming format "2020-03-16T00:00:00" is not compatible with datepicker
    this.timeStamp = vesselEvent.timestamp.split("T")[0];
    this.type = vesselEvent.type;
    this.vesselId = vesselEvent.vesselId;

    if (vesselEvent.typeId) {
      this.typeId = vesselEvent.typeId;
      this.selectedType = {
        trendEventTypeId: vesselEvent.typeId,
        trendEventTypeName: vesselEvent.name,
      };
    }
  }
}
